<template>
  <v-container v-if="hasPermission" fluid>
    <v-row justify="center">
      <v-col cols="12">
        <mex-heading content="SQL Statement - Editor" />
      </v-col>
    </v-row>
    <!-- SQL STATEMENT SELECTION ------------------------------------------------------------------>
    <v-row justify="center">
      <v-col v-if="!addMode" cols="5">
        <v-autocomplete
          v-model="selectedSqlStatement"
          :items="sqlStatementNames"
          color="primaryAccent"
          dense
          hide-details
          label="SQL Statements"
          outlined
          :readonly="!isAddOrEditMode"
          :filled="!isAddOrEditMode"
        >
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-title>{{ data.item.text }}</v-list-item-title>
              <v-list-item-subtitle class="ma-2">{{ data.item.description }}</v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col v-else cols="5">
        <v-text-field v-model="sqlStatementData.name" dense hide-details label="SQL Statement Name" outlined />
      </v-col>
      <v-col cols="auto">
        <mex-btn v-if="addMode" icon="mdi-playlist-edit" iconOnly @click="switchAddMode" />
        <mex-btn v-if="writeSQL && !addMode" icon="mdi-plus" iconOnly @click="switchAddMode" />
      </v-col>
      <v-col cols="auto">
        <mex-btn icon="mdi-help-circle-outline" tooltip="Help" iconOnly @click="openSqlHelpPage" />
      </v-col>
    </v-row>
    <!-- SQL STATEMENT CODE AREA ------------------------------------------------------------------>
    <v-row justify="center">
      <v-col cols="10" style="position: relative;">
        <v-overlay v-if="isAddOrEditMode" absolute color="white" opacity="0.1" class="pa-5" />
        <v-row>
          <v-col cols="9">
            <code-editor :code="sqlStatementData.statement" @code-updated="updateCode" />
          </v-col>
          <v-col cols="3" class="d-flex flex-column justify-center">
            <v-textarea
              v-model="sqlStatementData.description"
              dense
              hide-details
              label="SQL Statement Description"
              outlined
              :readonly="isAddOrEditMode"
              :filled="isAddOrEditMode"
              class="pb-5"
            />
            <mex-btn
              :disabled="!selectedSqlStatement"
              :color="validSyntax ? `primary` : `error`"
              rounded :icon="validSyntax ? `mdi-check` : `mdi-close`"
              content="Check Syntax"
              @click="checkStatementSyntax"
              class="elevated-btn"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- SQL STATEMENT DETAILS AREA --------------------------------------------------------------->
    <v-row justify="center">
      <v-col cols="10" style="position: relative;">
        <mex-sheet color="foreground" rounded>
          <v-row>
            <v-col cols="4">
              <v-row class="mb-2">
                <template>
                  <div class="d-flex justify-center">
                    <v-menu
                      open-on-hover
                      offset-x
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          dark
                          v-bind="attrs"
                          v-on="on"
                          :disabled="!editMode"
                        >
                          <v-icon>mdi-sitemap</v-icon>
                          Select all from Organization
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item
                          v-for="organization in organizationNames"
                          :key="organization.value"
                          @click="selectAllFromOrganization(organization)"
                        >
                          <v-list-item-title>{{ organization.text }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </template>
              </v-row>
              <v-row class="mb-2">
                <mex-btn :disabled="isAddOrEditMode" content="Select All" icon="mdi-checkbox-multiple-marked" @click="selectAllClinics" />
              </v-row>
              <v-row class="mb-2">
                <mex-btn :disabled="isAddOrEditMode" content="Deselect All" icon="mdi-checkbox-multiple-blank-outline" @click="deselectAllClinics" />
              </v-row>
              <v-row class="mb-2">
                <v-checkbox
                  v-model="sqlStatementData.showClinicsUnderMinimumVersion"
                  :disabled="isAddOrEditMode"
                  :label="meditexVersionCheckboxLabel"
                  @change="onMeditexVersionCheckboxChange"
                >
                </v-checkbox>
              </v-row>
            </v-col>
            <v-col cols="8">
              <v-autocomplete
                v-model="sqlStatementData.selectedClinics"
                :items="clinicNames"
                hide-details
                chips
                deletable-chips
                label="Clinics"
                multiple
                outlined
                :readonly="isAddOrEditMode"
                :filled="isAddOrEditMode"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </mex-sheet>
      </v-col>
    </v-row>
    <v-row justify="start">
      <v-col cols="12" class="d-flex justify-center">
        <v-switch
          v-model="sqlStatementData.active"
          color="warning"
          label="Active"
          prepend-icon="mdi-alert-octagon"
          :readonly="isAddOrEditMode"
          :disabled="isAddOrEditMode"
        />
      </v-col>
    </v-row>
    <v-dialog persistent v-model="showWarningDialog" max-width="500px">
      <v-card>
        <v-card-title>
          {{ warningDialog.title }}
        </v-card-title>
        <v-card-text class="text-h5">
          {{ warningDialog.text }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- call of function with generic name but - if overwritten - specific content,
           for more information see documentation in data-property -->
          <mex-btn v-if="warningDialog.okClick" content="OK" text @click="warningDialog.okClick()"></mex-btn>
          <v-spacer v-if="warningDialog.okClick && warningDialog.cancelClick"></v-spacer>
          <!-- call of function with generic name but - if overwritten - specific content,
           for more information see documentation in data-property -->
          <mex-btn v-if="warningDialog.cancelClick" content="CANCEL" text @click="warningDialog.cancelClick()"></mex-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- SAVE SQL STATEMENT ----------------------------------------------------------------------->
    <v-row justify="center" v-if="addMode">
      <v-col cols="auto">
        <mex-btn content="Save" icon="mdi-content-save" @click="saveSqlStatement" />
      </v-col>
      <v-col cols="auto" v-if="addModeChanges">
        <mex-btn content="Discard" icon="mdi-delete" @click="resetFields(true)" />
      </v-col>
    </v-row>
    <v-row justify="center" v-else-if="selectedSqlStatement">
      <v-col cols="auto" v-if="!editMode">
        <mex-btn v-if="writeSQL" content="Edit" icon="mdi-pencil" @click="editMode = true" />
      </v-col>
      <template v-else>
        <v-col cols="auto">
          <mex-btn content="Save" icon="mdi-content-save" @click="saveSqlStatement" />
        </v-col>
        <v-col cols="auto">
          <mex-btn content="Cancel" icon="mdi-delete" @click="discardChanges" />
        </v-col>
      </template>
    </v-row>
    <save-request :showSaveRequest="showSaveRequest" @closeSaveRequest="showSaveRequest = false" />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import CodeEditor from '../../components/LicSrvComponents/CodeEditor.vue';
import ClinicsService from '../../services/clinics.service';
import OrganizationsService from "../../services/organizations.service";
import SqlStatementsService from '../../services/sqlStatements.service';
import SaveRequest from '../../components/LicSrvComponents/SaveRequest.vue';
import requiredPermissions from '../../requiredPermissions';
import {assignPropertyIfExists} from "../../functions/assignPropertyIfExists";

export default {
  name: 'SqlStatementEditor',
  components: { SaveRequest, CodeEditor },
  computed: {
    ...mapGetters('sysAuth', ['getUserPermissions']),
    isAddOrEditMode() {
      return !(this.addMode || this.editMode);
    },
    addModeChanges() {
      if (
        this.sqlStatementData.name !== '' ||
        this.sqlStatementData.statement !== '' ||
        this.sqlStatementData.description !== '' ||
        this.sqlStatementData.selectedClinics.length > 0
      ) {
        return true;
      }
      return false;
    },
    writeSQL() {
      return this.getUserPermissions.includes('write_SQL Broadcaster');
    },
  },
  data() {
    return {
      // Visualization
      addMode: false,
      editMode: false,
      showSaveRequest: false,
      // Selectables
      sqlStatementNames: [],
      selectedSqlStatement: null,
      clinicNames: [],
      organizationNames: [],
      preSelection: {
        selectedSqlStatement: null,
      },
      // Data
      sqlStatementData: {
        sqlStatementID: null,
        name: '',
        description: '',
        // needs to be filled with spaces so that the code editor works
        statement: ' ',
        selectedClinics: [],
        active: false,
        showClinicsUnderMinimumVersion: false,
      },
      hasPermission: false,
      sqlBroadcasterConfig: null,
      minimumMeditexVersion: "2.8.8.0",
      meditexVersionCheckboxLabel: "",
      showWarningDialog: false,
      warningDialog: {
        okClick: () => {
          this.fetchClinicNames();
          this.showWarningDialog = false;
        },
        cancelClick: () => {
          this.sqlStatementData.showClinicsUnderMinimumVersion = true;
          this.showWarningDialog = false;
        },
        title: 'Warning',
        text: 'By deactivating this checkbox, every clinic under version 2.8.8.0 will be deselected. Continue?',
      },
      validSyntax: true,
    };
  },
  methods: {
    async selectAllFromOrganization(clickedOrga) {
      try {
        let newlySelectedClinics = [];
        const clinicResponse = await ClinicsService.getClinicNames(clickedOrga.value, this.sqlStatementData.showClinicsUnderMinimumVersion ? null : this.minimumMeditexVersion, true)
        clinicResponse.data.forEach((clinic) => {
          if (!clinic.notUseBroadcaster && !this.sqlStatementData.selectedClinics.includes(clinic.ClinicID)) {
            newlySelectedClinics.push({
              value: clinic.ClinicID,
              text: clinic.name,
            });
          }
        });
        this.sqlStatementData.selectedClinics = this.sqlStatementData.selectedClinics.concat(newlySelectedClinics.filter(x => !this.sqlStatementData.selectedClinics.includes(x)).map((x) => x.value));
      } catch(error) {
        this.$toast.error("Error while fetching clinic names for selected organizations");
      }
    },
    async onMeditexVersionCheckboxChange(newValue) {
      if (!newValue) {
        this.showWarningDialog = true;
      } else {
        // if any organization is selected, fetch clinic names for selected organizations, otherwise fetch all clinic names
        await this.fetchClinicNames();
      }
    },
    saveSqlStatement() {
      SqlStatementsService[(this.editMode ? 'update' : 'create') + 'SqlStatement'](this.sqlStatementData)
        .then((response) => {
          this.$toast.success('Successfully ' + (this.editMode ? 'updated' : 'created') + ' SQL Statement');
          this.reloadEditor(response.data.SqlStatementID);
        })
        .catch((err) => {
          this.$toast.error(err.response.data);
        });
    },
    openSqlHelpPage() {
      const routeData = this.$router.resolve({name: 'SqlStatementHelp'});
      window.open(routeData.href, '_blank');
    },
    switchAddMode() {
      if (!this.editMode) {
        if (this.addMode) {
          if (
            this.sqlStatementData.name !== '' ||
            this.sqlStatementData.statement !== '' ||
            this.sqlStatementData.description !== '' ||
            this.sqlStatementData.selectedClinics.length > 0
          ) {
            this.showSaveRequest = true;
          } else {
            this.resetFields();
          }
        } else {
          this.resetFields();
        }
      } else {
        this.showSaveRequest = true;
      }
    },
    resetFields(switchMode = true) {
      if (switchMode) {
        this.addMode = !this.addMode;
      }
      this.sqlStatementData.sqlStatementID = null;
      this.sqlStatementData.name = '';
      this.sqlStatementData.description = '';
      this.sqlStatementData.statement = '';
      this.sqlStatementData.selectedClinics = [];
      this.sqlStatementData.active = false;
      this.selectedSqlStatement = null;
    },
    discardChanges() {
      this.editMode = false;
      this.reloadEditor(this.selectedSqlStatement);
    },
    reloadEditor(sqlStatementID) {
      this.editMode = false;
      this.addMode = false;
      this.$router.push({
        name: 'ParamsReload',
        params: { name: 'SqlStatementEditor', reloadParams: { id: sqlStatementID } },
      });
    },
    updateCode(newVal) {
      this.sqlStatementData.statement = newVal;
    },
    fetchSqlStatementNames() {
      this.sqlStatementNames = [];
      SqlStatementsService.getSqlStatementNames()
        .then((sqlStatmentResponse) => {
          sqlStatmentResponse.data.forEach((statement) => {
            this.sqlStatementNames.push({
              value: statement.SqlStatementID,
              text: `${statement.name} | ${statement.description}`,
            });
          });

          if (this.preSelection && this.preSelection.selectedSqlStatement) {
            this.addMode = false;
            this.selectedSqlStatement = this.preSelection.selectedSqlStatement;
          }
        })
        .catch((err) => {
          this.$toast.error(err.response.data);
        });
    },
    fetchOrganizationNames() {
      this.organizationNames = [];
      OrganizationsService.getOrganizationNames()
        .then((organizationResponse) => {
          organizationResponse.data.forEach((organization) => {
            this.organizationNames.push({
              value: organization.OrganizationID,
              text: organization.name,
            });
          });
        })
        .catch((err) => {
          this.$toast.error(err.response.data);
        });
    },
    fetchClinicNames() {
      this.clinicNames = [];
      // get all clinic names with meditex-version >= this.minimumMeditexVersion at the beginning, clinic names of all version can be loaded with loadClinicNames
      ClinicsService.getClinicNames(null, this.sqlStatementData.showClinicsUnderMinimumVersion ? null : this.minimumMeditexVersion, true)
        .then((clinicResponse) => {
          clinicResponse.data.forEach((clinic) => {
            if (!clinic.notUseBroadcaster) {
              this.clinicNames.push({
                value: clinic.ClinicID,
                text: clinic.name,
              });
            }
          });
        })
        .catch((err) => {
          this.$toast.error(err.response.data);
        });
    },
    fetchSqlBroadcasterConfig() {
      SqlStatementsService.getSqlBroadcasterConfig()
        .then(response => {
          this.sqlBroadcasterConfig = response.data;
        })
        .catch(err => {
          this.$toast.error(err.response.data);
        })
    },
    getSelectedSqlStatementData() {
      if (this.selectedSqlStatement) {
        SqlStatementsService.getSqlStatement(this.selectedSqlStatement)
          .then((sqlStatementResponse) => {
            this.sqlStatementData.sqlStatementID = sqlStatementResponse.data.sqlStatementData.SqlStatementID;
            this.sqlStatementData.name = sqlStatementResponse.data.sqlStatementData.name;
            this.sqlStatementData.description = sqlStatementResponse.data.sqlStatementData.description;
            this.sqlStatementData.statement = sqlStatementResponse.data.sqlStatementData.statement;
            this.sqlStatementData.selectedClinics = sqlStatementResponse.data.assignedClinics.map((x) => x.ClinicID);
            this.sqlStatementData.active = sqlStatementResponse.data.sqlStatementData.active;
            this.sqlStatementData.showClinicsUnderMinimumVersion = sqlStatementResponse.data.sqlStatementData.showClinicsUnderMinimumVersion;
          })
          .catch((err) => {
            this.$toast.error(err.response.data);
          });
      }
    },
    deselectAllClinics() {
      this.sqlStatementData.selectedClinics = [];
    },
    selectAllClinics() {
      this.sqlStatementData.selectedClinics = this.clinicNames.map((x) => x.value);
    },
    checkStatementSyntax(){
      this.validSyntax = false;
      let syntaxRegExp = new RegExp(this.sqlBroadcasterConfig.statementSyntaxRegExp, "g");
      let statementUpper = this.sqlStatementData.statement.toUpperCase();
      if(statementUpper.match(syntaxRegExp)) {
        if (!statementUpper.includes("SELECT", statementUpper.indexOf("FROM"))) {
          let forbiddenKeywordMatch = false;
          this.sqlBroadcasterConfig.forbiddenKeywords.forEach(keyword => {
            if(statementUpper.includes(keyword)) {
              forbiddenKeywordMatch = true;
            }
          })
          if(!forbiddenKeywordMatch) {
            this.validSyntax = true;
          }
        }
      }
      if(this.validSyntax) {
        this.$toast.success("The SQL statement does match the requested syntax.")
      } else {
        this.$toast.error("The SQL statement does not match the requested syntax.")
      }

      this.sqlStatementData.statement = statementUpper;
    }
  },
  created() {
    // set checkbox-label
    this.meditexVersionCheckboxLabel = "Also show clinics with MedITEX-version < " + this.minimumMeditexVersion;

    this.$userPermissions.fetchCurrentUserPermissions(requiredPermissions.sqlBroadcaster, this.$store)
      .then((hasPermission) => {
        if (hasPermission) {
          this.hasPermission = true;
          this.preSelection.selectedSqlStatement = assignPropertyIfExists([this.$route.params, this.$route.query], 'id', this.$store, 'sqlStatementEditorSelectedStatement', 'setSqlStatementEditorSelectedStatement') || null;
          this.fetchOrganizationNames();
          this.fetchClinicNames();
          this.fetchSqlStatementNames();
          this.fetchSqlBroadcasterConfig();
        } else {
          this.$router.push({ name: "NotFound" });
        }
      })
      .catch(() => {
        this.$router.push({ name: "NotFound" });
      })
  },
  watch: {
    selectedSqlStatement: {
      handler() {
        this.getSelectedSqlStatementData();
        this.$store.commit('selectedProperties/setSqlStatementEditorSelectedStatement', this.selectedSqlStatement)
      },
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.editMode && !this.showSaveRequest) {
      this.showSaveRequest = true;
    } else if (
      this.addMode &&
      (this.sqlStatementData.name !== '' ||
        this.sqlStatementData.statement !== '' ||
        this.sqlStatementData.description !== '' ||
        this.sqlStatementData.selectedClinics.length > 0)
    ) {
      this.showSaveRequest = true;
    } else {
      next();
    }
  },
};
</script>

<style scoped>
.elevated-btn {
  position: relative;
  z-index: 5;
}
</style>
